import clsx from 'clsx';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useState } from 'react';

import { config } from '@endaoment-frontend/config';
import { routes } from '@endaoment-frontend/routes';
import { ArrowIcon, CloseIcon, HamburgerIcon, LogoIcon, LogoTextIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';
import { useTypeformModal } from '@endaoment-frontend/ui/smart';

import styles from './NavBar.module.scss';

const ActiveLink = ({
  href,
  children,
  onClick,
  className,
  target,
  rel,
  layoutId,
}: {
  href?: string;
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  target?: string;
  rel?: string;
  layoutId?: string;
}) => {
  const { asPath } = useRouter();
  const [isHovered, setIsHovered] = useState(false);

  const hrefPath = href ? href.replace(config.baseUrls.marketing, '') : '-';

  return (
    <Link
      href={href ?? '#'}
      target={target}
      rel={rel}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={clsx(asPath === hrefPath && styles['active'], className)}
      onClick={onClick}>
      {children}
      {!!isHovered && (
        <motion.div className={styles['active-indicator']} layoutId={layoutId} key='highlight'></motion.div>
      )}
    </Link>
  );
};

export const NavBar = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const closeHamburger = () => {
    setHamburgerOpen(false);
  };

  const { openTypeform: openDemoTypeform } = useTypeformModal({ id: 'B6A3Kqgd' });

  return (
    <>
      <motion.header
        key='header'
        className={styles['header']}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}>
        <div className={styles['header__inner']}>
          <nav className={clsx(styles['navigation'], styles['show-desktop'])}>
            <LayoutGroup>
              <ActiveLink layoutId='left-highlight' href={routes.marketing.donors()}>
                For Donors
              </ActiveLink>
              <ActiveLink layoutId='left-highlight' href={routes.marketing.advisors()}>
                For Financial Advisors
              </ActiveLink>
              <ActiveLink layoutId='left-highlight' href={routes.marketing.orgs()}>
                For Nonprofits
              </ActiveLink>
            </LayoutGroup>
          </nav>
          <div className={styles['logo']}>
            <Link href={routes.marketing.home()}>
              <LogoTextIcon width={128} height={30} className={styles['show-desktop']} />
              <LogoIcon width={30} className={styles['show-mobile']} />
            </Link>
          </div>
          <div className={styles['extra']}>
            <LayoutGroup>
              <ActiveLink layoutId='right-highlight' href={routes.marketing.about()} className={styles['show-desktop']}>
                About
              </ActiveLink>
              <ActiveLink layoutId='right-highlight' href={routes.marketing.learn()} className={styles['show-desktop']}>
                FAQ
              </ActiveLink>
              <ActiveLink
                layoutId='right-highlight'
                onClick={() => openDemoTypeform()}
                className={styles['show-desktop']}>
                Request Demo
              </ActiveLink>
            </LayoutGroup>
            <Button as='a' href={routes.app.dashboard()} variation='purple' className={styles['app-button']} filled>
              Go to App
              <ArrowIcon width={16} height={12} />
            </Button>
            <div className={clsx(styles['hamburger-icon'], styles['show-mobile'])}>
              <HamburgerIcon
                onClick={() => {
                  setHamburgerOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </motion.header>
      <AnimatePresence initial={false}>
        {!!hamburgerOpen && (
          <motion.div
            key='hamburger'
            className={styles['hamburger-menu']}
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.5 }}>
            <CloseIcon width={20} color='#696F8C' onClick={closeHamburger} />
            <nav className={clsx('flex-column', styles['navigation'])}>
              <ActiveLink href={routes.marketing.home()} onClick={closeHamburger}>
                Home
              </ActiveLink>
              <ActiveLink href={routes.marketing.donors()} onClick={closeHamburger}>
                For Donors
              </ActiveLink>
              <ActiveLink href={routes.marketing.advisors()} onClick={closeHamburger}>
                For Financial Advisors
              </ActiveLink>
              <ActiveLink href={routes.marketing.orgs()} onClick={closeHamburger}>
                For Nonprofits
              </ActiveLink>
              <ActiveLink href={routes.marketing.learn()} onClick={closeHamburger}>
                FAQ
              </ActiveLink>
              <ActiveLink href={routes.marketing.donationCalculator()} className={styles['show-desktop']}>
                Donation Calculator
              </ActiveLink>
              <ActiveLink onClick={() => openDemoTypeform()}>Demo</ActiveLink>
              <a href={routes.docs.home()} target='_blank' rel='noreferrer'>
                Docs
              </a>
              <Button as='a' href={routes.app.dashboard()} variation='purple'>
                Go to App
                <ArrowIcon width={20} height={12} />
              </Button>
            </nav>
            <div>
              <hr />
              <LogoIcon width={60} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
